<template>
  <div>
    <template v-if="profileMatter && matter">
      <h2>Assessment</h2>
      <Header
        class="pt-1 pb-4"
        :Matter="profileMatter"
        :section_id="section_id"
        :allows_crud="false"
      ></Header>

      <div class="mt-4" v-if="user.is_professor">
        <template v-if="section_id">
          <div :class="{ 'student-carousel-sticky': window_top > 318 }">
            <div class="student-carousel mb-2">
              <span class="student-label" v-if="window_top > 318">
                Estudiante:
              </span>
              <StudentCarousel
                :class="{ 'ml-3': window_top > 318 }"
                :section_id="section_id"
                @student_selcted="slotStudentSelected"
              ></StudentCarousel>
            </div>
          </div>
        </template>
        <div v-if="selected_student_id != null">
          <AssessmentTable
            :key="keyComponent"
            :matter="matter"
            :user_id="selected_student_id"
            :section_id="section_id"
          ></AssessmentTable>
        </div>
      </div>
      <div v-else-if="user.is_student">
        <AssessmentTable
          :key="keyComponent"
          :matter="matter"
          :user_id="user.id"
          :section_id="section_id"
        ></AssessmentTable>
      </div>
    </template>
    <DashboardCustomFab
      v-if="user.is_professor"
      class="noprint"
      :section_id="section_id"
      :show_assessment="false"
    ></DashboardCustomFab>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { toast } from "@/utils/utils";
export default {
  name: "AssessmentView",
  components: {
    Header: () => import("@/components/teaching/Planification/Header"),
    StudentCarousel: () => import("@/components/reusable/StudentCarousel"),
    AssessmentTable: () => import("./AssessmentTable"),
    DashboardCustomFab: () =>
      import("@/components/dashboard/DashboardCustomFab"),
  },
  data() {
    return {
      section_id: Number(this.$route.params.section_id),
      list: [],
      selected_student_id: null,
      keyComponent: 0,
      window_top: 0,
    };
  },
  computed: {
    ...mapGetters({
      sections: names.SECTIONS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      institution: "getInstitution",
      user: "getUser",
    }),
    section() {
      if (!this.section_id) return [];
      return this.sections.find((x) => x.id == this.section_id);
    },
    profileMatter() {
      if (!this.section) return null;
      return this.egress_profiles_matters.find(
        (x) => x.id == this.section.egress_profile_matter
      );
    },
    matter() {
      if (!this.profileMatter) return null;
      return this.matters.find((x) => x.id == this.profileMatter.matter);
    },
  },
  methods: {
    slotStudentSelected(student_id) {
      this.selected_student_id = student_id;
      this.keyComponent += 1;
    },
    onScroll(e) {
      this.window_top = e.target.documentElement.scrollTop;
    },
  },
  created() {
    this.$hasPermissions();
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store
      .dispatch(names.FETCH_SECTION, this.section_id)
      .then((section) => {
        this.$store
          .dispatch(
            names.FETCH_EGRESS_PROFILE_MATTER,
            section.egress_profile_matter
          )
          .then((matter) => {
            this.$store.dispatch(names.FETCH_MATTER, matter.matter);
          });
      });
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
<style scoped>
.student-carousel {
  display: flex;
  transform: translateY(46%);
}
.student-label {
  margin-left: 1%;
  transform: translateY(-20%);
}
.student-carousel-sticky {
  position: fixed !important;
  top: 30px;
  width: 100%;
  min-height: 1vh;
  padding-top: 4em;
  z-index: 300;
  background: var(--primary-color);
}
</style>